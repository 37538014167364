import React from 'react';
import { useIntl } from 'react-intl';
import { Calendar, MapPin } from 'react-feather';
import { useSelector } from 'react-redux';

import {
  Body,
  Container,
  Detail,
  DetailIcon,
  DetailLabel,
  Details,
  DetailsButton,
  DetailValue,
  Header,
  Status,
  Title,
} from './styled';
import { accountSelectors, generalActions } from '@store';
import { useAppDispatch } from '@hooks';
import { Issue } from '@domain/Issues/models/Issue';
import { issuesActions, issuesSelectors } from '@application/Issues/store';

import ZonedDateTime from '@components/shared/ZonedDateTime';
import IssueStatusName from '@presentation/Issues/IssueStatusName';

interface IProps {
  item: Issue;
}

const MyIssue: React.FC<React.PropsWithChildren<IProps>> = ({ item }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const objectName = useSelector(accountSelectors.getObjectName);

  return (
    <Container>
      <Header>
        <Title>{item.name ?? [item.number, item.type.name].join(' - ')}</Title>
        <Status>
          <IssueStatusName status={item.status} />
        </Status>
      </Header>
      <Body>
        <Details>
          {!!item.auditObject && (
            <Detail>
              <DetailIcon>
                <MapPin />
              </DetailIcon>
              <DetailLabel>{objectName.single}</DetailLabel>
              <DetailValue>{item.auditObject.name}</DetailValue>
            </Detail>
          )}
          {!!item.dueDate && (
            <Detail>
              <DetailIcon>
                <Calendar />
              </DetailIcon>
              <DetailLabel>{formatMessage({ id: 'DueDate' })}</DetailLabel>
              <DetailValue>
                <ZonedDateTime dateTime={item.dueDate} />
              </DetailValue>
            </Detail>
          )}
        </Details>
        <DetailsButton
          onClick={() => {
            dispatch(issuesActions.toggleFiltersModal(false));
            dispatch(generalActions.selectTableRows([]));
            dispatch(issuesActions.toggleIssueDetailsModal(item.id));
          }}
        >
          {formatMessage({ id: 'Details' })}
        </DetailsButton>
      </Body>
    </Container>
  );
};

export default MyIssue;
