import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '@hooks';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { config } from '@repo/shared/config';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { Issue } from '@domain/Issues/models/Issue';

import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';
import GroupedList from '@components/shared/GroupedList/GroupedList';
import MyIssue from '@presentation/Issues/MyIssuesList/MyIssue/MyIssue';
import { IssuesPage } from '@application/Issues/enums/IssuesPage';

interface IProps {}

const MyIssuesList: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, issues, meta, error } = useSelector(
    issuesSelectors.getMyIssues
  );

  useEffect(() => {
    return () => {
      dispatch(issuesActions.resetListData());
    };
  }, []);

  return (
    <GroupedList<Issue>
      list={issues.list}
      dictionary={issues.dictionary}
      loading={loading}
      meta={meta}
      error={error}
      empty={
        <EmptyTable
          text={formatMessage({ id: 'NoIssues' }, { linebreak: <br /> })}
          howItWorksUrl={config.urls.issuesSupport}
        />
      }
      onScrollStop={(pageNumber) => {
        dispatch(
          auditsActions.getAudits({
            pageNumber,
          })
        );
      }}
      Item={MyIssue}
    />
  );
};

export default MyIssuesList;
