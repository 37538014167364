import React from 'react';
import { useIntl } from 'react-intl';
import {Calendar, File } from 'react-feather';

import {
  Body,
  Container,
  Detail,
  DetailIcon,
  DetailLabel,
  Details,
  DetailsButton,
  DetailValue,
  Header,
  Status,
  Title,
} from './styled';
import { actionsActions, generalActions } from '@store';
import { useAppDispatch } from '@hooks';
import { Action } from '@domain/Actions/models/Action';

import ZonedDateTime from '@components/shared/ZonedDateTime';
import ActionStatusName from '@repo/shared/components/ActionStatusName';

interface IProps {
  item: Action;
}

const MyAction: React.FC<React.PropsWithChildren<IProps>> = ({ item }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Header>
        <Title>{item.name}</Title>
        <Status>
          <ActionStatusName status={item.status} />
        </Status>
      </Header>
      <Body>
        <Details>
          {!!item.audit && (
            <Detail>
              <DetailIcon>
                <File />
              </DetailIcon>
              <DetailLabel>{formatMessage({ id: 'Audit' })}</DetailLabel>
              <DetailValue>{item.audit.name}</DetailValue>
            </Detail>
          )}
          {!!item.dueDateInformation && (
            <Detail>
              <DetailIcon>
                <Calendar />
              </DetailIcon>
              <DetailLabel>{formatMessage({ id: 'DueDate' })}</DetailLabel>
              <DetailValue>
                <ZonedDateTime dateTime={item.dueDateInformation} />
              </DetailValue>
            </Detail>
          )}
        </Details>
        <DetailsButton
          onClick={() => {
            dispatch(actionsActions.toggleFiltersModal(false));
            dispatch(generalActions.selectTableRows([]));
            dispatch(actionsActions.toggleActionDetailsModal(item.id));
          }}
        >
          {formatMessage({ id: 'Details' })}
        </DetailsButton>
      </Body>
    </Container>
  );
};

export default MyAction;
