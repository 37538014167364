import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '@hooks';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';
import { actionsActions } from '@application/Actions/store/actionsActions';
import { config } from '@repo/shared/config';
import { Action } from '@domain/Actions/models/Action';

import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';
import MyAction from '@presentation/Actions/MyActionsList/MyAction/MyAction';
import GroupedList from '@components/shared/GroupedList/GroupedList';

interface IProps {}

const MyActionsList: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, actions, meta, error } = useSelector(
    actionsSelectors.getMyActions
  );

  useEffect(() => {
    return () => {
      dispatch(actionsActions.resetListData());
    };
  }, []);

  return (
    <GroupedList<Action>
      list={actions.list}
      dictionary={actions.dictionary}
      loading={loading}
      meta={meta}
      error={error}
      empty={
        <EmptyTable
          text={formatMessage(
            { id: 'NoCorrectiveActions' },
            { linebreak: <br /> }
          )}
          howItWorksUrl={config.urls.actionsSupport}
        />
      }
      onScrollStop={(pageNumber) => {
        dispatch(
          auditsActions.getAudits({
            pageNumber,
          })
        );
      }}
      Item={MyAction}
    />
  );
};

export default MyActionsList;
